<template>
  <v-container fluid>
    <v-col class="legal-text">
      <h1>Terms and Conditions</h1>
      <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</p>
      <h2>What's in these terms?</h2>
      <p>These terms tell you the rules for using our website https://worldmobiletoken.com/ (our site).</p>
      <h2>Who we are and how to contact us</h2>
      <p>https://worldmobiletoken.com/ is a site operated by World Mobile Token Limited (a BVI company) ("We"). We are registered in the British Virgin Islands under company number 2057398 and have our registered office at Floor 4, Banco Popular Building, Road Town, Totola, VG1110, B.V.I.</p>
      <p>We are a limited company.</p>
      <p>To contact us, please email <a href="mailto:support@worldmobiletoken.com">support@worldmobiletoken.com.</a></p>
      <h2>By using our site you accept these terms</h2>
      <p>By using our site, you confirm that you accept these terms of use and that you agree to comply with them.</p>
      <p>If you do not agree to these terms, you must not use our site.</p>
      <h2>There are other terms that may apply to you</h2>
      <p>These terms of use refer to the following additional terms, which also apply to your use of our site:</p>
      <ul>
      <li>Our <a href="https://worldmobiletoken.com/privacy-policy">Privacy Policy</a> </li>
      <li>Our <a href="https://worldmobiletoken.com/cookie-policy"> Cookie Policy </a>  which sets out information about the cookies on our site.</li>
      </ul>
      <h2>We may make changes to these terms</h2>
      <p>We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.</p>
      <h2>We may make changes to our site</h2>
      <p>We may update and change our site from time to time to reflect changes to our products, our users' needs and our business priorities.</p>
      <h2>We may suspend or withdraw our site</h2>
      <p>Our site is made available free of charge.</p>
      <p>We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>
      <p>You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>
      <h2>We may transfer this agreement to someone else</h2>
      <p>We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</p>
      <h2>You must keep your account details safe</h2>
      <p>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>
      <p>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</p>
      <p>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a href="mailto:dataprotection@worldmobiletoken.com"> dataprotection@worldmobiletoken.com </a> .</p>
      <h2>How you may use material on our site</h2>
      <p>We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
      <p>You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>
      <p>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
      <p>Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged (except where the content is user-generated).</p>
      <p>You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
      <p>If you print off, copy, download, share or repost any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
      <h2>No text or data mining, or web scraping</h2>
      <p>You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our site or any services provided via, or in relation to, our site. This includes using (or permitting, authorising or attempting the use of):</p>
      <ul>
      <li>Any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the site or any data, content, information or services accessed via the same.</li>
      <li>Any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.</li>
      </ul>
      <p>The provisions in this clause should be treated as an express reservation of our rights in this regard, including for the purposes of Article 4(3) of Digital Copyright Directive ((EU) 2019/790).</p>
      <p>This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us.</p>
      <h2>Do not rely on information on this site</h2>
      <p>The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.</p>
      <p>Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.</p>
      <h2>We are not responsible for websites we link to</h2>
      <p>Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</p>
      <p>We have no control over the contents of those sites or resources.</p>
      <h2>User-generated content is not approved by us</h2>
      <p>This website may include information and materials uploaded by other users of the site, including to bulletin boards and chat rooms. This information and these materials have not been verified or approved by us. The views expressed by other users on our site do not represent our views or values.</p>
      <h2>How to complain about content uploaded by other users</h2>
      <p>If you wish to complain about content uploaded by other users, please contact us on <a href="mailto:dataprotection@worldmobiletoken.com"> dataprotection@worldmobiletoken.com </a>.</p>
      <h2>Our responsibility for loss or damage suffered by you</h2>
      <h2>Whether you are a consumer or a business user:</h2>
      <p>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
      </p>
      <h2>If you are a business user:</h2>
      <ul>
        <li>We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it.</li>
        <li>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</li>
        <li class="no-bullet">
          <ul>
            <li>use of, or inability to use, our site; or</li>
            <li>use of or reliance on any content displayed on our site.</li>
          </ul>
        </li>
        <li>In particular, we will not be liable for:</li>
        <li class="no-bullet">
          <ul>
            <li>loss of profits, sales, business, or revenue;</li>
            <li>business interruption;</li>
            <li>loss of anticipated savings;</li>
            <li>loss of business opportunity, goodwill or reputation; or</li>
            <li>any indirect or consequential loss or damage.</li>
          </ul>
        </li>
      </ul>
      <h2>If you are a consumer user:</h2>
      <p>Please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
      <h2>How we may use your personal information</h2>
      <p>We will only use your personal information as set out in our  <a href="https://worldmobiletoken.com/privacy-policy"> https://worldmobiletoken.com/privacy-policy </a>.</p>
      <h2>Uploading content to our site</h2>
      <p>Any content you upload to our site will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are required to grant us and other users of our site a limited licence to use, store and copy that content and to distribute and make it available to third parties.te</p>
      <p>We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.</p>
      <p>We have the right to remove any posting you make on our site if, in our opinion, your post does not comply with what we consider to be reasonably acceptable.</p>
      <p>You are solely responsible for securing and backing up your content.</p>
      <h2>Rights you are giving us to use material you upload</h2>
      <p>When you upload or post content to our site, you grant us the following rights to use that content:</p>
      <ul>
        <li> a worldwide, non-exclusive, royalty-free, transferable licence to use, reproduce, distribute, prepare derivative works of, display, and perform that user-generated content in connection with the service provided by the website and across different media including to promote the site or the service forever;</li>
        <li> a worldwide, non-exclusive, royalty-free, transferable licence for other users, partners or advertisers to use the content for their purposes forever.</li>
      </ul>
      <h2>We are not responsible for viruses and you must not introduce them</h2>
      <p>We do not guarantee that our site will be secure or free from bugs or viruses.</p>
      <p>You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.</p>
      <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</p>
      <h2>Rules about linking to our site</h2>
      <p>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
      <p>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
      <p>You must not establish a link to our site in any website that is not owned by you.</p>
      <p>Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
      <p>We reserve the right to withdraw linking permission without notice.</p>
      <p>If you wish to link to or make any use of content on our site other than that set out above, please contact <a href="mailto:dataprotection@worldmobiletoken.com"> dataprotection@worldmobiletoken.com </a>.</p>
      <h2>Rewards Calculators</h2>
      <p>The calculations provided are on our site are on an “as is” basis, without warranty of any kind. We do not warrant that the operation of our rewards calculators will be uninterrupted or error-free. You assume sole responsibility for results obtained from the use of our rewards calculators and the conclusions drawn from such use. We shall have no liability for any damage caused by errors or omissions in any information provided to you by our rewards calculators.</p>
      <p>Our rewards calculators are provided for illustrative purposes only, they do not take your personal circumstances into consideration and the results are not guaranteed to be accurate or fit for any purpose. The presented results should not be taken as professional advice or relied upon as the sole basis for making important decisions.</p>
      <p>Any collateral contract or any terms, implied or incorporated into this agreement, whether by statute, common law or otherwise are hereby excluded, including the implied conditions warranties or other terms as to satisfactory quality, fitness for purpose or the use of reasonable skill and care.</p>
      <h2>Which country's laws apply to any disputes?</h2>
      <p>These terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.</p>
    </v-col>
    <v-btn
      color="primary darken-1 white--text back-button"
      elevation="2"
      @click="$router.push('/')"
    >
      Go back
    </v-btn>
  </v-container>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.back-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
</style>
